import React from "react"
import Layout from "../components/Layout"

export default class Index extends React.Component { 
  render() {
    return (
        <Layout location={this.props.location}>
            <div className="bg-[#f5f5f5]">
            <div className="max-w-7xl grid grid-cols-3 gap-8 mx-auto py-10">
                <div>
                    <div className="bg-lagoon aspect-2"></div>
                    <p>Lagoon</p>
                </div>
                <div>
                    <div className="bg-cyan aspect-2"></div>
                    <p>Cyan</p>
                </div>
                <div>
                    <div className="bg-vivid aspect-2"></div>
                    <p>Vivid</p>
                </div>
                <div>
                    <div className="bg-coral aspect-2"></div>
                    <p>Coral</p>
                </div>
                <div>
                    <div className="bg-pink aspect-2"></div>
                    <p>Pink</p>
                </div>
                <div>
                    <div className="bg-green aspect-2"></div>
                    <p>Green</p>
                </div>
                <div>
                    <div className="bg-offblack aspect-2"></div>
                    <p>Offblack</p>
                </div>
                <div>
                    <div className="bg-darkgray aspect-2"></div>
                    <p>Darkgray</p>
                </div>
                <div>
                    <div className="bg-gray aspect-2"></div>
                    <p>Gray</p>
                </div>
                <div>
                    <div className="bg-lightgray aspect-2"></div>
                    <p>Lightgray</p>
                </div>
                <div>
                    <div className="bg-white aspect-2"></div>
                    <p>White</p>
                </div>
            </div>
            </div>
        </Layout>
    )
  }
}
 